<template>
  <div class="wrap">
    <div class="search clearfix">
      <div>
        <el-select
            style="max-width:240px"
            class="form_item"
            clearable
            size="small"
            v-model="queryParams.type"
            :placeholder="$t('handleType')"
        >
          <el-option
              v-for="item in operateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-select
            style="max-width:240px"
            class="form_item"
            clearable
            size="small"
            v-model="queryParams.account"
            :placeholder="$t('Account ')"
        >
          <el-option
              v-for="item in accountOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-date-picker
            class="form_item"
            size="small"
            v-model="queryParams.time"
            type="daterange"
            :start-placeholder="$t('operatingstarttime')"
            :end-placeholder="$t('operatingEndTime')"
            :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <div class="btn_wrap">
        <el-button size="small" type="primary" class="fr" @click="search"
        >{{ $t('search') }}
        </el-button
        >
        <el-button size="small" @click="resetQuery">
          {{$t('reset')}}
        </el-button>
      </div>
    </div>
    <div class="tableBlock">
      <el-table
          :data="tableData"
          style="width: 100%"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 70 }"
          :tableLoading="loading"
      >
        <el-table-column :label="$t('handleTime')" min-width="170px">
          <template slot-scope="scope">
            {{ scope.row.created_time|secondFormat }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('operatingAmount')" min-width="152px">
          <template slot-scope="scope">
            {{ scope.row.amount / 100|textFormat }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('Account ')" min-width="152px">
          <template slot-scope="scope">
            <span v-if="scope.row.account_type ===2">
              {{ $t('CashRedEnvelope') }}
            </span>
            <span v-else-if="scope.row.account_type ===3">
              {{ $t('BusinessRedEnvelope ') }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handleType')" min-width="130px">
          <template slot-scope="scope">
            <span v-if="scope.row.trade_type === 1">
              {{ $t('Recharge ') }}
            </span>
            <span v-else-if="scope.row.trade_type === 2">
              {{ $t('withdraw') }}
            </span>
            <span v-else-if="scope.row.trade_type === 3">
              {{ $t('RedEnvelopes') }}
            </span>
            <span v-else-if="scope.row.trade_type === 5">
              {{ $t('returnGo') }}
            </span>
            <span v-else>
              -
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('operatingStatus')" min-width="140px">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">
              {{$t('Processing')}}
            </span>
            <span v-else-if="scope.row.status === 1">
              {{$t('succeed')}}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('AccountBalance ')" min-width="140px">
          <template slot-scope="scope">
            {{ scope.row.balance / 100|textFormat }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('orderNumber ')" min-width="186px">
          <template slot-scope="scope">
            {{ scope.row.trade_no|textFormat }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" min-width="80px">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="open(scope.row)">
              {{ $t('Detail') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
          :current-page.sync="currentPage"
      >
      </el-pagination>
    </div>
    <el-dialog
        :visible.sync="detailDialog"
        width="500px"
        :destroy-on-close="true"
    >
      <operationDetail v-if="detailDialog" :currentItem="currentItem"/>
    </el-dialog>
  </div>
</template>

<script>
import operationDetail from "~bac/components/conference/redEnvelopeAdmin/operationDetail";

export default {
  components: {
    operationDetail,
  },
  data() {
    return {
      queryParams: {
        type: null,
        account: null,
        time: null,
      },
      tableData: [],
      total: 0,
      currentPage: 1,
      loading: false,
      tableHeight: 100,
      detailDialog: false,
      currentItem: {},
      operateOptions: [
        {
          label: this.$t('Recharge '),//充值
          value: 1,
        },
        {
          label: this.$t('RedEnvelopes'),//发红包
          value: 3,
        },
        {
          label: this.$t('returnGo'),//返还
          value: 5,
        },
        {
          label: this.$t('withdraw'),//提现
          value: 2,
        },
      ],
      accountOptions: [
        {
          label: this.$t('CashRedEnvelope'),//现金红包
          value: 2,
        },
        {
          label: this.$t('BusinessRedEnvelope '),//业务红包
          value: 3,
        },
      ],
    };
  },
  watch:{
    currentPage(){
      this.getList();
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      let params = {}
      params.start = this.currentPage - 1
      params.length = 10
      params.company_id=this.USER_INFO.company_id
      if (this.queryParams.type) {
        params.trade_type = this.queryParams.type
      }
      if (this.queryParams.account) {
        params.account_type = this.queryParams.account
      }
      if (this.queryParams.time) {
        params.start_time = this.$moment(this.queryParams.time[0]).unix()
        params.end_time = this.$moment(this.queryParams.time[1]).unix()
      }
      let res = await this.$store.dispatch('baseConsole/accountRecordsList', params)
      if (res.success) {
        this.tableData = res.data.data
        this.total = res.data.count
        this.loading = false
      }

    },
    search() {
      this.currentPage=1
      this.getList()
    },
    resetQuery(){
      let _params=Object.assign({},this.queryParams)
      Object.keys(_params).forEach(item=>{
        _params[item]=null
      })
      this.queryParams=_params
    },
    open(item) {
      this.currentItem = item;
      this.detailDialog = true
    }
  },
};
</script>

<style scoped lang="less">
.title {
  font-size: 20px;
  margin: 10px 0;
  padding: 0 12px;
}
.search {
  padding: 10px 12px 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div:first-child {
    width: 1000px;
    .form_item {
      margin-left: 20px;
      &:first-child{
        margin-left: 0;
      }
    }
  }
  .btn_wrap {
    flex: 1;
    display: flex;
    justify-content:flex-end;
  }
}
</style>
