<template>
  <div class="wrap" v-loading="loading">
    <div class="project one" v-if="showModule === 2">
      <div class="item">
        <div class="key">{{$t('WithdrawalAccount ')}}{{$t('colon')}}</div>
        <div class="value">78252314863221333225</div>
      </div>
      <div class="item">
        <div class="key">{{$t('withdrawAmount')}}{{$t('colon')}}</div>
        <div class="value">1000.00</div>
      </div>
      <div class="item">
        <div class="key">{{$t('HandlingFee')}}{{$t('colon')}}</div>
        <div class="value">20.00</div>
      </div>
      <div class="item">
        <div class="key">{{$t('ActualAmountReceived')}}{{$t('colon')}}</div>
        <div class="value">980.00</div>
      </div>
    </div>
    <div class="project two" v-if="showModule === 1">
<!--      <div class="item">-->
<!--        <div class="key">{{$t('AccountRecharge')}}{{$t('colon')}}</div>-->
<!--        <div class="value">78252314863221333225</div>-->
<!--      </div>-->
      <div class="item">
        <div class="key">{{$t('RechargeAmount')}}{{$t('colon')}}</div>
        <div class="value">{{resultData.amount/100|numberFormat|toFixedTwo}}</div>
      </div>
<!--      <div class="item">-->
<!--        <div class="key">{{$t('HandlingFee')}}{{$t('colon')}}</div>-->
<!--        <div class="value">20.00</div>-->
<!--      </div>-->
      <div class="item">
        <div class="key">{{$t('ActualAmountReceived')}}{{$t('colon')}}</div>
        <div class="value">{{resultData.reality_amount/100|numberFormat|toFixedTwo}}</div>
      </div>
    </div>
    <div class="project three" v-if="showModule === 5">
      <div class="item">
        <div class="key">{{$t('RedEnvelopeName')}}{{$t('colon')}}</div>
        <div class="value" :class="[resultData.deleted === 1?'':'clickValue']" @click="goToDetail">
          {{resultData.topic_en|priorFormat(resultData.topic_zh,LOCALE)}}
        </div>
      </div>
      <div class="item">
        <div class="key">{{$t('AmountIssued')}}{{$t('colon')}}</div>
        <div class="value">{{resultData.total_amount/100|numberFormat|toFixedTwo}}</div>
      </div>
      <div class="item">
        <div class="key">{{$t('ActualAmount')}}{{$t('colon')}}</div>
        <div class="value">{{resultData.sum_amount/100|numberFormat|toFixedTwo}}</div>
      </div>
      <div class="item">
        <div class="key">{{$t('RefundAmount')}}{{$t('colon')}}</div>
        <div class="value">{{resultData.restitution/100|numberFormat|toFixedTwo}}</div>
      </div>
    </div>
    <div class="project four" v-if="showModule === 3">
      <div class="item">
        <div class="key">{{$t('RedEnvelopeName')}}{{$t('colon')}}</div>
        <div class="value" :class="[resultData.deleted === 1?'':'clickValue']" @click="goToDetail">{{resultData.topic_en|priorFormat(resultData.topic_zh,LOCALE)}}</div>
      </div>
      <div class="item">
        <div class="key">{{$t('AmountIssued')}}{{$t('colon')}}</div>
        <div class="value">{{resultData.restitution/100|numberFormat|toFixedTwo}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentItem: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      loading:false,
      showModule:null,
      resultData:{}
    };
  },
  created() {
    this.showModule=this.currentItem.trade_type
  },
  mounted() {
    this.getList()
  },
  methods:{
    async getList(){
      this.loading=true
      let params ={}
      params.account_trade_id=this.currentItem.account_trade_id
      params.trade_type=this.currentItem.trade_type
      params.account_type=this.currentItem.account_type
      params.amount=this.currentItem.amount
      if(!this.currentItem.envelope_id){
        params.envelope_id=0
      }else{
        params.envelope_id=this.currentItem.envelope_id
      }
      if(this.currentItem.remarks === null){
        params.remarks='null'
      }else{
        params.remarks=this.currentItem.remarks
      }
      // params.remarks="{}"
      params._NOCLEAR=true
      let res=await this.$store.dispatch('baseConsole/accountRecordsDetails',params)
      if(res.success){
        this.loading=false
        this.resultData=res.data
      }
    },
    goToDetail(){
      if(this.resultData.deleted === 1){
        return 
      }
      if(this.currentItem.account_type === 2){
        this.$router.push({
          path:"/console/conference/cash_detail",
          query:{
            parameter:this._encode({
              envelope_id:this.resultData.envelope_id,
            }),
          },
        });
      }else{
        this.$router.push({
          path:"/console/conference/receiveBusiness",
          query:{
            parameter:this._encode({
              envelope_id:this.resultData.envelope_id,
            }),
          },
        });
      }
    }
  },
  filters: {
    toFixedTwo(input){
      if(input === '-'){
        return input
      }else{
        return parseFloat(input).toFixed(2)
      }

    }
  }
};
</script>

<style scoped lang="less">
.project {
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .key {
      width: 165px;
      font-size: 14px;
      text-align: right;
    }
    .value {
      padding-left: 20px;
      width: 300px;
    }
  }
}
.clickValue{
  color: #1890FF;
  cursor: pointer;
}
</style>
